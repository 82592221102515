let VUE_APP_CDCBaseURL = '';
let VUE_APP_APIBaseURL = '';
let VUE_APP_NCCDBaseURL = '';
let VUE_APP_SASService = '';

const HOST_ENV = getHostEnvironment();

function getHostEnvironment() {
  var hostName = window.location.hostname;
  if (hostName.includes('localhost')) return 'localhost';
  if (hostName.includes('-qa.apps.ecpaas-dev.cdc.gov')) return 'qa';
  if (hostName.includes('-dev.apps.ecpaas-dev.cdc.gov')) return 'dev';
  if (hostName.includes('-int.apps.ecpaas.cdc.gov')) return 'int';
  // default to prod
  return 'prod';
} // .getHostEnvironment


switch (HOST_ENV) {
  case 'localhost':
    //VUE_APP_APIBaseURL = 'https://yrbssanalysis-dev.apps.ecpaas-dev.cdc.gov';
    VUE_APP_APIBaseURL = 'http://localhost:64382';
    VUE_APP_SASService = 'YRBSDev';
    VUE_APP_CDCBaseURL = 'https://wwwdev.cdc.gov/'
    VUE_APP_NCCDBaseURL = 'https://nccddev.cdc.gov/'
    break;
  case 'dev':
    VUE_APP_APIBaseURL = 'https://yrbssanalysis-dev.apps.ecpaas-dev.cdc.gov';    
    VUE_APP_SASService = 'YRBSDev';
    VUE_APP_CDCBaseURL = 'https://wwwdev.cdc.gov/'
    VUE_APP_NCCDBaseURL = 'https://nccddev.cdc.gov/'
    break;
  case 'qa':
    VUE_APP_APIBaseURL = 'https://yrbssanalysis-qa.apps.ecpaas-dev.cdc.gov';    
    VUE_APP_SASService = 'YRBSDev';
    VUE_APP_CDCBaseURL = 'https://wwwdev.cdc.gov/'
    VUE_APP_NCCDBaseURL = 'https://nccdqa.cdc.gov/'
    break;
  case 'int':
    VUE_APP_APIBaseURL = 'https://yrbs-analysis-int.apps.ecpaas.cdc.gov';    
    VUE_APP_SASService = 'nccdphp';
    VUE_APP_CDCBaseURL = 'https://www.cdc.gov/'
    VUE_APP_NCCDBaseURL = 'https://nccd.cdc.gov/'
    break;
  case 'prod':
    VUE_APP_APIBaseURL = 'https://yrbs-analysis.cdc.gov';    
    VUE_APP_SASService = 'nccdphp';
    VUE_APP_CDCBaseURL = 'https://www.cdc.gov/'
    VUE_APP_NCCDBaseURL = 'https://nccd.cdc.gov/'
    break;
  default:
}


module.exports = {
  VUE_APP_APIBaseURL: VUE_APP_APIBaseURL,  
  VUE_APP_SASService: VUE_APP_SASService,
  VUE_APP_CDCBaseURL: VUE_APP_CDCBaseURL,
  VUE_APP_NCCDBaseURL: VUE_APP_NCCDBaseURL
}
